import * as React from 'react'

import { AuthProvider, Routes, Types } from '@grandvisionhq/auth'

type AuthConfig = React.ComponentProps<typeof AuthProvider>['settings']

/**
 * All routes are empty because MyAccount is disabled on this OpCo.
 * Unfortunately we cannot remove them entirely, because they are required types.
 *
 */

const routes: Routes = {
  appointments: '',
  changePassword: '',
  createAccount: '',
  linkExpired: '',
  login: '',
  logout: '',
  profile: '',
  resetPassword: '',
  setPassword: '',
  settings: '',
  signup: '',
  unlinkOrder: '',
  verify: '',
  wishlist: '',
}

const optIns: Types.OptInItem[] = [
  {
    value: 'newsletter',
    titleKey: 'account.optIn.newsletter',
    tooltipKey: 'account.optIn.newsletter.tooltip',
    isInSignup: true,
  },
]

export const getAuthConfig = (): AuthConfig => ({
  routes,
  optIns,
  apiUrl: '',

  clientId: '',
  customDomain: '',
  domain: '',
  basePath: '',
  checkoutRoute: '/checkout/shipping',
})
